import ada from "./modules/ada";
import astra from "./modules/astra";
import contact from "./modules/dialogs/contact";
import video from "./modules/dialogs/video";
import pagination from "./modules/pagination";
import sliderBanner from "./modules/sliders/banner";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderProduct from "./modules/sliders/product";
import sliderTestimonial from "./modules/sliders/testimonial";
import test from "./modules/test";
import utils from "./modules/utils";

// Utils
// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
// utils.e10kRemoveEmptyPTags(
// 	document.querySelectorAll(
// 		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
// 	)
// );

// Video fade
// utils.e10kVideoFadeIn(document.querySelector(".background-video"));

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
// astra.init();

// Pagination
// pagination.init();

// Dialogs
contact.init();
// video.init();

// Sliders
sliderBanner.init();
// sliderGrid.init();
// sliderLogo.init();
sliderProduct.init();
sliderTestimonial.init();

// Test
// test.log();
