import EmblaCarousel from "embla-carousel";
import AutoHeight from "embla-carousel-auto-height";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Testimonial slider");

	const testimonialSliderEls = document.querySelectorAll(
		".testimonial-slider.embla"
	);
	if (testimonialSliderEls) {
		testimonialSliderEls.forEach((el) => {
			testimonialSlider(el);
		});
	}
}

function testimonialSlider(el) {
	const wrap = el;
	const viewPort = wrap.querySelector(".embla__viewport");
	const prevBtn = wrap.querySelector(
		".testimonial-slider .embla__button--prev"
	);
	const nextBtn = wrap.querySelector(
		".testimonial-slider .embla__button--next"
	);

	// Toggle status based on media queries
	let status = true;
	const slides = wrap.querySelectorAll(".testimonial-slider .slide");

	// Min width 992
	const mediaQuery992 = window.matchMedia("(min-width: 992px)");
	if (mediaQuery992.matches && slides.length <= 3) {
		wrap.classList.add("disabled");
		status = false;
	}

	function handleMediaQuery992Change(event) {
		if (event.matches && slides.length <= 3) {
			wrap.classList.add("disabled");
			status = false;
		} else {
			wrap.classList.remove("disabled");
			status = true;
		}
	}
	mediaQuery992.addEventListener("change", handleMediaQuery992Change);

	// Min width 768
	const mediaQuery768 = window.matchMedia("(min-width: 768px)");
	if (mediaQuery768.matches && slides.length <= 2) {
		wrap.classList.add("disabled");
		status = false;
	}

	function handleMediaQuery768Change(event) {
		if (event.matches && slides.length <= 2) {
			wrap.classList.add("disabled");
			status = false;
		} else {
			wrap.classList.remove("disabled");
			status = true;
		}
	}
	mediaQuery768.addEventListener("change", handleMediaQuery768Change);

	// Max width 768
	const mediaQuery767 = window.matchMedia("(max-width: 767px)");
	if (mediaQuery767.matches && slides.length <= 1) {
		wrap.classList.add("disabled");
		status = false;
	}

	function handleMediaQuery767Change(event) {
		if (event.matches && slides.length <= 1) {
			wrap.classList.add("disabled");
			status = false;
		} else {
			wrap.classList.remove("disabled");
			status = true;
		}
	}
	mediaQuery767.addEventListener("change", handleMediaQuery767Change);

	const embla = EmblaCarousel(
		viewPort,
		{
			active: true,
			align: "start",
			breakpoints: {
				"(min-width: 992px)": { align: "center" }
			},
			containScroll: "trimSnaps",
			dragFree: true,
			loop: true
		},
		[ClassNames()]
	);

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Autoplay
	// const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
